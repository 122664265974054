@import "../../lib/css/variables.scss";
.nav-tabs {
  background-color: $green;
  .nav-link {
    color: $white;
    font-size: 1.2rem;
    font-weight: bold;
    &:hover {
      color: $green;
      cursor: pointer;
    }
    &.active {
      background-color: $white;
      color: $green;
    }
  }
}
