.tempsensor {
  .card-title {
    font-size: 1.3em;
    text-align: center;
  }
  .alerttemp {
    animation-name: alert-color;
    animation-duration: 1s;
    animation-iteration-count: infinite;
  }
  .switchoff {
    background-color: rgb(45 83 53 / 31%);
  }
}
.sensoritem {
  margin-bottom: 30px;

  .ribbon {
    position: absolute;
    right: 16px;
    top: 0px;
    z-index: 1;
    overflow: hidden;
    width: 75px;
    height: 75px;
    text-align: right;

    span {
      font-size: 10px;
      font-weight: bold;
      color: #fff;
      text-transform: uppercase;
      text-align: center;
      line-height: 20px;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      width: 100px;
      display: block;
      background: #79a70a;
      background: #f50d05;
      box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
      position: absolute;
      top: 19px;
      right: -21px;
      :before {
        content: "";
        position: absolute;
        left: 0px;
        top: 100%;
        z-index: -1;
        border-left: 3px solid #8f0808;
        border-right: 3px solid transparent;
        border-bottom: 3px solid transparent;
        border-top: 3px solid #8f0808;
      }
      :after {
        content: "";
        position: absolute;
        right: 0px;
        top: 100%;
        z-index: -1;
        border-left: 3px solid transparent;
        border-right: 3px solid #8f0808;
        border-bottom: 3px solid transparent;
        border-top: 3px solid #8f0808;
      }
    }
  }
}
.temp-item {
  text-align: -webkit-center;
}
.tempwidget {
  .carousel {
    margin: 10px;
    .control-dots {
      margin: 0px 0;
    }
  }
}

.sensorkwh {
  .valuekwh {
    font-size: 2em;
    font-weight: bold;
    text-align: center;
  }
}
.show-elecra {
  .valuekwh {
    font-size: 2.6em;
    font-weight: bold;
    text-align: center;
  }
  .box-titel {
    position: relative;
    form {
      position: absolute;
      top: 0px;
      right: 10%;
      width: 200px;
    }
  }
}
.fasehead {
  margin-left: 2%;
  font-weight: normal;
}
.tempsensor {
  .details {
    position: relative;
    display: flex; // Voegt flexbox toe
    align-items: center; // Centreert de inhoud verticaal
    justify-content: start; // Centreert de inhoud horizontaal
    height: 3rem;
    .valuekwh {
      font-size: 2rem;
      font-weight: bold;
      padding-left: 10px;
    }
    .meeting {
      position: absolute;
      right: 10px;
      top: 18px;
    }
  }
  a {
   text-decoration: none;

    .valuekwh {
      font-size: 2rem;
      font-weight: bold;
      padding-left: 10px;
    }
  }
}
.accordion {
  .btn-link {
    color: #d96a29;
    font-size: 1.8rem;
    font-weight: bold;
  }
}
.btn:focus,
.btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem #d96a2947;
  text-decoration: none;
}
.btn-link:hover,
.btn-link:focus,
.btn-link:active,
.btn-link.active,
.show > .btn-link.dropdown-toggle {
  color: #d96a29 !important;
  text-decoration: underline;
  box-shadow: 1px 2px 0 0.2rem #d96a2956 !important;
}
.map-container {
  width: 100vw;
  height: 900px;
}

.popup-content {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  padding: 10px;
  max-width: 200px;
  .titelpopup {
    font-size: 1.2rem;
    font-weight: bold;
    text-align: center;
    display: block;
  }
}

.popup-content::before {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 8px;
  border-style: solid;
  border-color: #ccc transparent transparent transparent;
}
.valueswitchoff {
  font-size: 1.2rem;
  vertical-align: middle;
  font-weight: bold;
  margin-left: 0.5rem;
}
#tooltip-anchor-hide {
  width: 200px;
  padding: 0.5rem;
  margin: 0.5rem;
}
