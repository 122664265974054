.valuweKwh_stand {
  font-size: 6rem;
  display: block;
}

.accommodatietabel {
  th{
    white-space: nowrap;
  }
  tbody {
    tr:nth-child(even) {
      td {
        table {
          margin: 0px;
          tr {
            td {
              background-color: transparent;
              border: none;
            }
          }
        }
      }
    }
    tr:nth-child(odd) {
      td {
        background-color: rgb(217 106 41 / 10%);
        table {
          margin: 0px;
          tr {
            td {
              background-color: transparent;
              border: none;
            }
          }
        }
      }
    }
    td {
      align-content: center;
      .badge {
        font-size: 1rem;
      }
    }
  }
}
