/*
 * Skin: Blue
 * ----------
 */
.skin-blue .main-header .navbar {
  background-color: #2d5335;
}
.fullscreen {
  margin-left: 0;
}
.login-logo {
  background-color: #ffffff;
  text-align: left;
  padding-left: 20px;
  color: #2d5335;
  font-weight: bolder;
}
.wild-logo {
  background-color: transparent;
  border-top: 0px;
  height: 400px;
  background-image: url("../../../../public/brand/backgrounds/footer.png");
  background-size: cover;
  background-position: right;
  padding-top: 365px;
}
.footer-text {
  position: absolute;
  top: -20px;
}
.transpbackground {
  background-color: transparent;
}
.content-box {
  box-sizing: content-box;
}
.skin-blue .main-header .navbar .nav > li > a {
  color: #ffffff;
  text-decoration: none;
}
.skin-blue .main-header .navbar .nav > li > a:hover,
.skin-blue .main-header .navbar .nav > li > a:active,
.skin-blue .main-header .navbar .nav > li > a:focus,
.skin-blue .main-header .navbar .nav .open > a,
.skin-blue .main-header .navbar .nav .open > a:hover,
.skin-blue .main-header .navbar .nav .open > a:focus,
.skin-blue .main-header .navbar .nav > .active > a {
  background: rgba(0, 0, 0, 0.1);
  color: #f6f6f6;
}
.skin-blue .main-header .navbar .sidebar-toggle {
  color: #ffffff;
}
.skin-blue .main-header .navbar .sidebar-toggle:hover {
  color: #f6f6f6;
  background: rgba(0, 0, 0, 0.1);
}
.skin-blue .main-header .navbar .sidebar-toggle {
  color: #fff;
}
.skin-blue .main-header .navbar .sidebar-toggle:hover {
  background-color: #2d5335;
}
@media (max-width: 767px) {
  .skin-blue .main-header .navbar .dropdown-menu li.divider {
    background-color: rgba(255, 255, 255, 0.1);
  }
  .skin-blue .main-header .navbar .dropdown-menu li a {
    color: #fff;
  }
  .skin-blue .main-header .navbar .dropdown-menu li a:hover {
    background: #2d5335;
  }
}
a.btn.btn-default.btn-flat {
  background-color: #d96a29;
  color: #ffffff;
}
.skin-blue .main-header .logo {
  background-color: #ffffff;
  color: #2d5335;
  border-bottom: 0 solid transparent;
}
.skin-blue .main-header .logo:hover {
  background-color: #ffffff;
}
.skin-blue .main-header li.user-header {
  background-color: #2d5335;
}
.skin-blue .content-header {
  background: transparent;
}
.main-sidebar {
  background-color: #ffffff;
}
.skin-blue .wrapper,
.skin-blue,
.skin-blue .left-side {
  background-color: #ffffff;
  background-size: cover;
  background-position: center;
}
.skin-blue .user-panel > .info,
.skin-blue .user-panel > .info > a {
  color: #ffffff;
}
.skin-blue .sidebar-menu > li.header {
  color: #2d5335;
  background: #ffffff;
}
.skin-blue .sidebar-menu > li > a {
  border-left: 3px solid transparent;
}
.skin-blue .sidebar-menu > li:hover > a,
.skin-blue .sidebar-menu > li.active > a,
.skin-blue .sidebar-menu > li.menu-open > a {
  background-color: #ffffff;

  color: #2d5335;
}
.skin-blue .sidebar-menu > li.active > a {
  border-left-color: #2d5335;
}
.skin-blue .sidebar-menu > li > .treeview-menu {
  margin: 0 1px;
  background: #2d5335;
}
.skin-blue .sidebar a {
  color: #2d5335;
  text-decoration: none;
}
.skin-blue .sidebar a:hover {
  text-decoration: none;
}
.skin-blue .sidebar-menu .treeview-menu > li > a {
  color: #ffffff;
  text-decoration: none;
}
.skin-blue .sidebar-menu .treeview-menu > li.active > a,
.skin-blue .sidebar-menu .treeview-menu > li > a:hover {
  color: #ffffff;
}
.skin-blue .sidebar-form {
  border-radius: 3px;
  border: 1px solid #374850;
  margin: 10px 10px;
}
.skin-blue .sidebar-form input[type="text"],
.skin-blue .sidebar-form .btn {
  box-shadow: none;
  background-color: #374850;
  border: 1px solid transparent;
  height: 35px;
}
.skin-blue .sidebar-form input[type="text"] {
  color: #666;
  border-top-left-radius: 2px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 2px;
}
.skin-blue .sidebar-form input[type="text"]:focus,
.skin-blue .sidebar-form input[type="text"]:focus + .input-group-btn .btn {
  background-color: #fff;
  color: #666;
}
.skin-blue .sidebar-form input[type="text"]:focus + .input-group-btn .btn {
  border-left-color: #fff;
}
.skin-blue .sidebar-form .btn {
  color: #999;
  border-top-left-radius: 0;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 0;
}
.skin-blue.layout-top-nav .main-header > .logo {
  background-color: #ffffff;
  color: #2d5335;
  border-bottom: 0 solid transparent;
}
.skin-blue.layout-top-nav .main-header > .logo:hover {
  background-color: #3b8ab8;
}

@media (min-width: 767px) {
  .login-background_recreatie {
    background-image: url("../../../../public/brand/backgrounds/background.jpg");
    background-size: cover;
    background-position: center;
  }
}
