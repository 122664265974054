$white: #ffffff;
$lightgreen: #b1bf41;
$green: #2f5234;
$lightgreen_10: #39a9351f;
$lightgreentrans: #39a9352b;
$greentrans: #39a93578;
$roze: #ff5ba8;
$orange: #d96a29;
$blauw: #67c6f3;
$textdark: #201f37;
$main: $green;
$second: $lightgreen;
$third: $orange; 
$fourth: $roze;
$offline: #d96a29;


$accordion-button-active-bg: $lightgreen;
$accordion-button-active-color: $white;
$accordion-button-bg:  $lightgreentrans;
$secondary-bg-subtle: $lightgreen;
