.settings {
  .sub-sidebar {
    height: 50px;
    .btn-toggle-nav li {
      margin-left: 20px;
    }
  }
  .websubtable {
    border-left: solid 2px #2f5234;
    margin-left: 15px;
  }
}

.media {
  .media_item {
    .custom-img {
      height: 160px;
      width: 100%;
      object-fit: contain;
      background-color: #d96a290f;
    }
    .box-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}

.types {
  .htmlelements {
    .icon-container {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      i {
        font-size: 24px;
      }
    }
  }
  .container-content {
    .container-item {
      .container-sub-item {
        height: 75px;
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
.fieldfortranslate {
  position: relative;

  .translatebutton {
    float: inline-end;
    position: absolute;
    top: 0;
    right: 0;
  }
}


.flex-container {
  display: flex;
  flex-direction: row;
  .col-lg-9 {
    flex: 1; /* Zorgt ervoor dat de content links de meeste ruimte inneemt */
  }
  
  .col-lg-3 {
    flex: 0 0 300px; /* Zorgt ervoor dat de submenu een vaste breedte heeft, pas aan naar behoefte */
  }
}

